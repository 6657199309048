import { Component, Input } from '@angular/core';
import { TranslationService } from '../../../services/translation.service';
import { IApp } from '../../../core/classes/apps.interface';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrl: './card-details.component.scss',
})
export class CardDetailsComponent {
  @Input()
  details!: IApp;

  constructor(public translate: TranslationService) {}
}
