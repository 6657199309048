import { Component, OnInit } from '@angular/core';
import { ECOLAB, PRIVACY_POLICY, TERMS_CONDITIONS } from '../../../feature/constants/DI.contants';
import { privarcy_url, terms_conditions_url } from '../../../feature/constants/endpoints/urls';
import { TranslationService } from '../../../services/translation.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  Ecolab = ECOLAB;
  PRIVACY_POLICY = PRIVACY_POLICY;
  TERMS_CONDITIONS = TERMS_CONDITIONS;
  copyRightYear: string = '';

  constructor(public translate: TranslationService) {}

  ngOnInit(): void {
    this.translate.getPersistLanguge();
    this.copyRightYear =  new Date().getFullYear().toString();
  }

  privacyTerms(clickedText: string): void {
    if (clickedText === 'PRIVACY_POLICY') {
      window.open(`${environment.ECOLAB_WEB}privacy-policy`, '_blank');
    }
    if (clickedText === 'TERMS_CONDITIONS') {
      window.open(`${environment.ECOLAB_WEB}terms-of-use`, '_blank');
    }
  }
}
