// ADD ALL APPLICATION CONSTANTS HERE
export const MYHOMEPAGE = 'My Homepage';
export const TVD = 'My Total Value Delivered';
export const EBR = 'My Business Review';
export const CONNECT = 'Connect';
export const Ecolab3D = 'Ecolab 3D';
export const ECOLAB = 'Ecolab';
export const PRIVACY_POLICY = 'Privacy Policy';
export const TERMS_CONDITIONS = 'Terms of Use';
export const EDITGLOBALPREFERENCES = 'Edit Global Prefrences';
export const LOGOUT = 'Log Out';
export const GLOBAL_PREFERENCES = 'Global Preferences';
export const NOTE_DATA = 'Note: These preferences selected here will affect your My Homepage digital apps and experiences.';
export const CANCEL = 'Cancel';
export const APPLY_PREFERENCES = 'Apply Preferences';
export const LANGUAGE_PREFERENCES = 'Language Preference';
export const CURRENCY_PREFERENCES = 'Currency Preference';
export const UNIT_OF_MEASURES_PREFERENCES = 'Unit of Measure Preference';
export const IMPERIAL_UNIT_PREFERENCES = 'Imperial (inches, ounces, feet, pounds, cups, gallons, miles, etc.)';
export const METRIC_UNIT_PREFERENCES = 'Metric (meter, centimeter, liter, kilometer, gram, etc.)';
export const END_SESSION_PATH = 'endsession';
export const NO_APPS = 'No Apps Available';
export const NO_APPS_LOGIN_STATUS = `Your current log-in status does not connect to links or apps that can be displayed here.`;