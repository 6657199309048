import { Component, Input } from '@angular/core';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrl: './card-details.component.scss',
})
export class CardDetailsComponent {
  @Input() details: any;

  constructor(public translate: TranslationService) {}

  ngOnInit() {
  }
}
