import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HomeModule } from './feature/components/home/home.module';
import { SharedModule } from './shared/shared.module';
import {
  MSAL_INSTANCE,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig,DIprotectedResources, b2cPolicies, DOTNETprotectedResources } from './auth-config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
// import {CookieService} from 'ngx-cookie-service';

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HomeModule,
    AppRoutingModule,
    HttpClientModule,
    ApmModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: DIprotectedResources.MULEAPI.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [
            DIprotectedResources.MULEAPI.endpoint,
            DIprotectedResources.MULEAPI.scopes,
          ],
          [
            DOTNETprotectedResources.DOTNET.endpoint,
            DOTNETprotectedResources.DOTNET.scopes,
          ],
        ]),
      }
    ),
  ],
  providers: [
    // CookieService,
    ApmService,
    {
      provide:  MSAL_INSTANCE,
      useFactory: () => {
        return new PublicClientApplication({
          auth: {
            clientId: environment.clientId,
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            knownAuthorities: [b2cPolicies.authorityDomain],
            postLogoutRedirectUri: '/',
          },
        })
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(service: ApmService) {
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: 'one-customer-DI',
      serverUrl: environment.APM_SERVICE_URL
    })
    // FOR FUTURE USE CASE - ITS HARD CODED IN BELOW CODE ONLY FOR TESTING
    // apm.setUserContext({
    //   'username': 'test-user',
    //   'id': 'test-id'
    // })
  }
}
