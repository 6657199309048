import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ILanguage } from '../core/classes/language.interface';

@Injectable({
  providedIn: 'root',
})
export class LangaugesService {
  constructor(private http: HttpClient) {}

  getLanguages(): Observable<ILanguage> {
    return this.http.get<ILanguage>(`${environment.DOTNET_BASE_URL}/globalpreferences/languages`);
  }
}
