import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { LoginPageComponent } from './login-page/login-page.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslationService } from '../../../services/translation.service';
// import {CookieService} from 'ngx-cookie-service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
} 
@NgModule({
  declarations: [LandingPageComponent, LoginPageComponent],
  imports: [CommonModule, HomeRoutingModule, SharedModule,  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  })],
  providers: [TranslationService
    // CookieService
  ],
  exports: [LandingPageComponent, LoginPageComponent],
})
export class HomeModule {}
