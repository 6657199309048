import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersistPreferencesService } from './persist-preferences.service';
import { DISSOService } from './di-sso.service';
import { IUserPreference } from '../core/classes/preference.interface';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  defaultLang: any;
  userPreference: string = '';

  constructor(
    public translate: TranslateService,
    private persistPreferencesService: PersistPreferencesService,
    private ssoService: DISSOService
  ) {}

  email = this.ssoService.getUserMailID();
  getPersistLanguge() {
    this.persistPreferencesService
      .getPreferences(this.email)
      .subscribe((selectedLanguage: IUserPreference) => {
        this.userPreference = selectedLanguage?.data?.userPreferences?.langKey;
        this.defaultLang = this.translate.getBrowserLang();
        console.log(this.defaultLang)
        this.defaultLang = this.translate.use(this.userPreference);
      });
  }
}
