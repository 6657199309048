import { Component, Injectable } from '@angular/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../../auth-config';
import { DISSOService } from '../../../services/di-sso.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-end-user-session',
  templateUrl: './end-user-session.component.html',
  styleUrl: './end-user-session.component.scss'
})
@Injectable({ providedIn: 'root' })
export class EndUserSessionComponent {

  constructor() {
    this.endSession();
  }


  /**
   * Method used to clear the User's session
   */
  async endSession() {

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    const homeAccountId = msalInstance.getAllAccounts()[0]?.homeAccountId;
    console.log('End session: MSAL Instance: ' + homeAccountId);
    const currentAccount = msalInstance.getAccountByHomeId(homeAccountId);
    const idToken = msalInstance.getAllAccounts()[0]?.idToken

    // Automatically on page load
    msalInstance.logoutRedirect({

      account: currentAccount,
      idTokenHint: idToken,
      onRedirectNavigate: (url) => {
        console.log("DI OnRedirectNavigate: " + url);
        // Return false to stop navigation after local logout
        //return false;
      },
      postLogoutRedirectUri: environment.ECOLAB_WEB
    });
  }
}
