import { Component } from '@angular/core';

@Component({
  selector: 'app-test-comp',
  template: '<app-card-details [details]="test"></app-card-details>',
})
export class TestCompComponent {

  test = {
      applicationCode: 'test',
      applicationName: 'test',
      applicationDisplayName: 'test',
      appIconLink: 'test',
      appDeepLink: 'https://onecustomer-dev.azurewebsites.net/TotalValue',
      helpText: 'test',
    };
}
