<div class="user-preference-filter">
  <mat-card class="user-preference-filter-card" *ngIf="userPreferenceClicked">
    <mat-card-content class="user-preference-filter-content">

      <div *ngFor="let preference of userPrerence" class="align-data">
        <div (click)="getPreference(preference)">
          <img [src]="preference?.img" [id]="preference?.img" />
          <label class="label-data" [id]="preference?.label">{{preference?.label}}</label>
          <div *ngIf="preference.label !== ''">
            <hr />
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="global-user-select" *ngIf="isSettingsClicked">
  <form [formGroup]="preferencesForm" (ngSubmit)="onSubmit()">
    <div class="global-preference-filter">
      <mat-card class="global-preference-filter-card">
        <mat-card-header class="global-heading-title" id="header">
          <mat-card-title id="title">
            <label class="global-preference-align">{{'DI.GLOBAL_PREFERENCES'| translate}}</label>
            <img src="../../../../assets/images/close.png" (click)="closePrefernce()" />
          </mat-card-title>
        
        </mat-card-header>
        <mat-card-content>
          <div>
            <hr />
          </div>
          <div class="global-note" id="note">
            <label>
              {{'DI.NOTE_DATA'| translate}}
            </label>
          </div>
          <div class="global-language">
            <label class="global-language-label">{{'DI.LANGUAGE_PREFERENCES'|translate}}</label>
            <div class="select">
            <select formControlName="langaugesControl">
              <option *ngFor="let lang of languages" [ngValue]="lang?.language">{{lang?.language}}</option>
            </select>
          </div>
          </div>
          <div class="global-language">
            <label class="global-language-label">{{'DI.CURRENCY_PREFERENCES' | translate}}</label>
            <div class="select" >
              <select formControlName="currencyControl">
                <option *ngFor="let curr of allCurrencies" [ngValue]="curr?.currencyName">{{ curr?.currencyName }}</option>
              </select>
            </div>
          </div>

          <div class="global-units">
            <label class="units">{{'DI.UNIT_OF_MEASURES_PREFERENCES' | translate}}</label>
            <div class="radio-group">
              <div class="radio-group-align">
                <input type="radio"  name="unit" formControlName="unit" value="Imperial">
                <label class="radio-group-label">{{'DI.IMPERIAL_UNIT_PREFERENCES' | translate}}</label>
              </div>
              <div  class="radio-group-align">
                <input type="radio" name="unit" formControlName="unit" value="Metric">
                <label class="radio-group-label">{{'DI.METRIC_UNIT_PREFERENCES' | translate}}</label>
              </div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="global-footer" id="footer">
          <button class="btn btn-cancel" (click)="closePrefernce()" id="cancel">{{'DI.CANCEL'| translate}}</button>
          <button class="btn" id="apply" [disabled]="preferencesForm.pristine || !preferencesForm.dirty || preferencesForm.invalid" 
            [ngClass]="(preferencesForm.pristine || !preferencesForm.dirty || preferencesForm.invalid) ? 'btn-disabled' : 'btn-apply'">{{'DI.APPLY_PREFERENCES' | translate}}</button>
        </mat-card-footer>
      </mat-card>
    </div>
  </form>
</div>