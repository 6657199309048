export const environment = {
  production: false,
  name: 'stage environment',
  // AZURE B2C RELATED CONFIGURATION
  clientId: 'f2638432-7c1e-44ac-9cad-9faf34627222',
  SIGN_UP_SIGN_IN_AUTHORITY:
    'https://wintersnowb2c.b2clogin.com/stg-account.ecolab.com/B2C_1A_Connect_OIDC_SIGNIN',
  AUTHORITYDOMAIN: 'wintersnowb2c.b2clogin.com',
  // DOTNET SCOPES
  DOTNET_READ_SCOPE: 'https://stg-account.ecolab.com/1C_Api_stg/1C.Read',
  DOTNET_WRITE_SCOPE: 'https://stg-account.ecolab.com/1C_Api_stg/1C.Write',
  // MULE SCOPE
  MULE_READ_SCOPE:
    'https://stg-account.ecolab.com/6de9da30-511c-4fc4-9b3f-467d55f9a73b/scope:read.only',
  // DOT NET BASE URL
  DOTNET_BASE_URL: 'https://stg-onecustomer-api.ecolab.com',
  // MULE BASE URL
  MULE_BASE_URL: 'https://api-cloudhubcentral-st.ecolab.com/one-customer/v1',
  // DOT COM
  ECOLAB_WEB: 'https://dotcom-stage-cds.ecolab.com/',
  // APM SERVICE URL
  APM_SERVICE_URL : 'https://7f18513b91d543dfa791112eabe1324a.apm.eastus2.azure.elastic-cloud.com:443'
};
