<div *ngIf="details" class="card-header">
    <div class="card-header-text">{{details?.applicationDisplayName}}</div>
    <div class="card-header-nav">
        <img src="../../../../assets/images/arrow-right.png" />

    </div>
</div>
<div class="card-header-body">
    <div *ngIf="(details?.appIconLink) ; else noImg">
        <div class="card-header-body-text">
            <div class="business-img">
                <img class="img-url" [src]="details?.appIconLink"/>
            </div>
        </div>
    </div>
    <ng-template #noImg>
        <div class="business-no-img card-header-body-text">
            <p>{{'DI.NO_DISPLAY' | translate}}</p>
        </div>
        
    </ng-template>
    <div class="card-header-body">
        <p *ngIf="details?.helpText; else noHelpText">{{details?.helpText}}</p>
        <ng-template #noHelpText>
            <p class="help-text">{{'DI.NO_IMG' | translate}}</p>
        </ng-template>
    </div>
</div>