import { Component, HostListener } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { DISSOService } from './services/di-sso.service';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PersistPreferencesService } from './services/persist-preferences.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  status: any;
  isMsalLoggedIn: boolean = false;

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    this.ssoService.checkLoggedOutService().subscribe(info => {
      this.status = info?.data?.login?.isLoggedOut;
      if (this.status) {
        this.ssoService.logoutDI(true);
      }
    })
  }

  constructor(
    private ssoService: DISSOService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userpreferenceService: PersistPreferencesService
  ) {
    this.isMsalLoggedIn = false;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {
          return msg.eventType === EventType.LOGIN_SUCCESS;
        }),
      )
      .subscribe((result: EventMessage) => {
        this.isMsalLoggedIn = true;
        
        this.userpreferenceService.
          updateUserLoginStatus(
            this.authService.instance.getAllAccounts()[0].localAccountId,
            false).subscribe({});
      });
  }


  ngOnInit(): void {
    if (!this.isMsalLoggedIn) {
      /*
      check user status from data source.
      IF user state = Logged out, force the user to log-out
      ELSE continue the user session
       */
      this.ssoService.checkLoggedOutService().subscribe(info => {
        this.status = info?.data?.login?.isLoggedOut;
        if (this.status) {
          this.ssoService.logoutDI(true);
        }
      })
    }

  }

}
