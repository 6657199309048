import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EBR, MYHOMEPAGE, NO_APPS, NO_APPS_LOGIN_STATUS, TVD } from '../../../constants/DI.contants';
import { DISSOService } from '../../../../services/di-sso.service';
import { GetCDMDataService } from '../../../../services/get-cdmdata.service';
import { IApp } from '../../../../core/classes/user-preference.interface';
import { SpinnerService } from '../../../../services/spinner-service.service';
import { TranslationService } from '../../../../services/translation.service';
import { PersistPreferencesService } from '../../../../services/persist-preferences.service';
// import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent implements OnInit {
  MYHOMEPAGE = MYHOMEPAGE;
  homeCards: IApp[] = [];
  email: any;
  userPreferenceChanged: any;
  dropDown: any;
  EBR = EBR;
  TVD = TVD;
  status: any;
  userName: any = '';
  cardsLength: number | undefined = undefined;
  NO_APPS = NO_APPS;
  NO_APPS_LOGIN_STATUS = NO_APPS_LOGIN_STATUS;




  constructor(
    private router: Router,
    private ssoService: DISSOService,
    private cdmService: GetCDMDataService,
    private spinner: SpinnerService,
    private persistPreferencesService: PersistPreferencesService,
    public translate: TranslationService) {
    this.cdmService.SharingUserName.subscribe((name: any) => {
      this.userName = name;
    })
  }

  ngOnInit(): void {

    this.cdmService.userPreferenceSubject.subscribe((data) => {
      this.userPreferenceChanged = data;
      this.getUserInfo();
    });
    this.getUserInfo();
    this.email = this.ssoService.getUserMailID();
  }

  getUserInfo(): void {
    var spinnerRef = this.spinner.start();
    this.cdmService?.getUserApplications().subscribe(
      (CDMData) => {
        this.translate.getPersistLanguge();
        this.cardsLength = CDMData?.response?.application.length;
        this.cdmService.SharingUserName.next(CDMData?.response?.firstName.concat(' ').concat(CDMData?.response?.lastName));
        if (CDMData && CDMData?.response?.application.length > 0) {
          this.homeCards = CDMData?.response?.application.filter(
            (card) => card.appDeepLink !== null
          );
          this.getPersistLanguge();
        }
        this.spinner.stop(spinnerRef);
      },
      (error) => {
        if (error) {
          this.spinner.stop(spinnerRef);
        }
      }
    );
  }

  gotTo(cardType: any): void {
    if (cardType) {
      window.open(cardType?.appDeepLink, '_blank');
    }
  }

  getPersistData() {
    this.cdmService.userPreferenceSubject.subscribe((data) => {
      this.userPreferenceChanged = data;
      this.getUserInfo();
    });
  }

  getPersistLanguge() {
    this.persistPreferencesService
      .getPreferences(this.email)
      .subscribe((data) => {
        if (data?.data?.userPreferences?.language) {
          let selection = {language:data?.data?.userPreferences?.language,currency: data?.data?.userPreferences?.currency,uom: data?.data?.userPreferences?.uom};
          this.dropDown = this.persistPreferencesService.passUserPreference(selection['language']);
          this.persistPreferencesService.passUserPreference(selection);
        } else {
          this.dropDown = 'English';
          this.persistPreferencesService.passUserPreference(this.dropDown);
        }
      });
  }

  ngOnDestroy() {
    this.cdmService.userPreferenceSubject.unsubscribe();
  }
}