import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUserSelection } from '../core/classes/user-preference.interface';

@Injectable({
  providedIn: 'root'
})
export class PersistPreferencesService {
  public persistPreferencesData = new Subject<IUserSelection>();

  constructor(private http: HttpClient) { }
  postPreferences(preferences: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    const url = `${environment.DOTNET_BASE_URL}/globalpreferences/userpreferences`;
    const body = JSON.stringify(preferences);
    return this.http.post(url, body, { 'headers': headers });
  }

  getPreferences(emailId: string): Observable<any> {
    const url = `${environment.DOTNET_BASE_URL}/globalpreferences/userpreferences`;
    return this.http.get<any>(url, {
      params: {
        EmailId: emailId
      }
    }).pipe(shareReplay());
  }

  passUserPreference(selection: IUserSelection) {
    this.persistPreferencesData.next(selection);
  }

  /**
   * Method used to update the user login status.
   * @param emailId Current user email Id
   * @param loginStatus status of login
   * @returns Observable User login status as response
   */
  updateUserLoginStatus(emailId: string, loginStatus: boolean): Observable<any> {
    let loginStatusPayload = {
      emailId: emailId,
      isLoggedOut: loginStatus,
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.DOTNET_BASE_URL}/globalpreferences/user/logout`;
    const body = JSON.stringify(loginStatusPayload);
    return this.http.post(url, body, { headers: headers });
  }
}
