import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUser } from '../core/classes/user-preference.interface';

@Injectable({
  providedIn: 'root',
})
export class GetCDMDataService {
  constructor(private http: HttpClient) {}

  public userPreferenceSubject = new Subject<string>();
  public SharingUserName = new Subject();  

  passValue(data: any) {
    this.userPreferenceSubject.next(data);
  }

  getUserApplications(): Observable<IUser> {
    const url = ``
    return this.http.get<IUser>(
      `${environment.MULE_BASE_URL}/users/applications`
    ).pipe(shareReplay());
  }
}
