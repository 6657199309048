import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  EventMessage,
  EventType
} from '@azure/msal-browser';
import { DISSOService } from '../../../../services/di-sso.service';
import { GetCDMDataService } from '../../../../services/get-cdmdata.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
// import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit {
  navIcons: string[] = [];
  homeIcon: string = '../../../../../assets/images/home.png';
  loginDisplay: boolean = false;
  userName: string = '';

  constructor(
    private router: Router,
    public ssoService: DISSOService,
    private cdmService: GetCDMDataService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
    // public cookieService:CookieService
  ) {
    this.cdmService.SharingUserName.subscribe((name: any) => {
      this.userName = name;
    });
  }

  ngOnInit(): void {

    


    this.ssoService?.userSSO().subscribe(
      (result: EventMessage) => {
        console.log(result);
      }
    );
    this.ssoService.checkProgress().subscribe(
      () => {
        this.setLoginDisplay();
      }
    );
    // this.getUserName();
    this.navIcons = [this.homeIcon];
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.ssoService?.getAllAccountsLength();
  }

  // Below code gives user name from claims 
  // commented for now, as we are getting username from CDM, to be removed after testing, 
  // along with its reference in ngOnInit 
  // getUserName(): void {
  // this.userName = this.ssoService?.getLoggedInUserName();
  // }

  navigateToApp(): void {
    this.router.navigate(['/']);
  }
}
