import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { DISSOService } from '../../../services/di-sso.service';
import { IPreferenceData, IUserSelection } from '../../../core/classes/preference.interface';
import {
  APPLY_PREFERENCES,
  CANCEL,
  CURRENCY_PREFERENCES,
  GLOBAL_PREFERENCES,
  IMPERIAL_UNIT_PREFERENCES,
  LANGUAGE_PREFERENCES,
  LOGOUT,
  METRIC_UNIT_PREFERENCES,
  NOTE_DATA,
  UNIT_OF_MEASURES_PREFERENCES,
} from '../../../feature/constants/DI.contants';
import { LangaugesService } from '../../../services/langauges.service';
import { CurrenciesService } from '../../../services/currencies.service';
import { FormBuilder, Validators } from '@angular/forms';
import { PersistPreferencesService } from '../../../services/persist-preferences.service';
import { TranslationService } from '../../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { GetCDMDataService } from '../../../services/get-cdmdata.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss',
})
export class PreferencesComponent implements OnInit, AfterViewInit, OnDestroy{
  @Input() userPreferenceClicked: boolean = false;
  GLOBAL_PREFERENCES = GLOBAL_PREFERENCES;
  NOTE_DATA = NOTE_DATA;
  CANCEL = CANCEL;
  APPLY_PREFERENCES = APPLY_PREFERENCES;
  LANGUAGE_PREFERENCES = LANGUAGE_PREFERENCES;
  CURRENCY_PREFERENCES = CURRENCY_PREFERENCES;
  UNIT_OF_MEASURES_PREFERENCES = UNIT_OF_MEASURES_PREFERENCES;
  IMPERIAL_UNIT_PREFERENCES = IMPERIAL_UNIT_PREFERENCES;
  METRIC_UNIT_PREFERENCES = METRIC_UNIT_PREFERENCES;
  isSettingsClicked: boolean = false;
  userPrerence: IPreferenceData[] = [];
  allCurrencies: any;
  languages: any;
  isSubmitted = false;
  email:string = '';
  payload: any;
  languageKey:any;
  langk:any
  currCode:any;
  preferenceChanged: any
  private destroy:Subject<void> = new Subject();
  preferenceMemory: IUserSelection | undefined;
  currencyName: string = '';

  constructor(
    private ssoService: DISSOService,
    private persistPreferencesService: PersistPreferencesService,
    private langaugesService: LangaugesService,
    private currenciesService: CurrenciesService,
    public translateService:TranslateService,
    public fb: FormBuilder,
    public translate: TranslationService,
    public getCDMDataService: GetCDMDataService,
  ) {}

  preferencesForm = this.fb.group({
    langaugesControl: ['English',],
    currencyControl: ['United States Dollar'],
    unit: ['Imperial'],
  });

  changeCurrencies(e: any): void {
    this.currencyControl?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeLanguage(e: any) {
    this.langaugesControl?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  get langaugesControl() {
    return this.preferencesForm.get('langaugesControl');
  }

  get currencyControl() {
    return this.preferencesForm.get('currencyControl');
  }

  ngOnInit() {   
    this.translateService.stream('DI').subscribe((data:any)=> {
        this.userPrerence = [
          {
            label: data.EDITGLOBALPREFERENCES,
            img: '../../../../assets/images/globe.png',
          },
          {
            label: data.LOGOUT,
            img: '../../../../assets/images/logout.png',
          },
        ];
     });

    this.persistPreferencesService.persistPreferencesData
    .pipe(takeUntil(this.destroy))
    .subscribe((data) => {
      this.preferenceMemory = data;
      if(this.preferenceMemory.currency) {
        this.allCurrencies.find((curr: any) => {
          if(curr.currencyCode === this.preferenceMemory?.currency) {
              this.currencyName = curr.currencyName;
          }
        });
      }
    })
  }

  ngAfterViewInit() {
    this.email = this.ssoService.getUserMailID();
     this.langaugesService.getLanguages()
     .pipe(takeUntil(this.destroy))
     .subscribe(lang => {
      this.languages = lang?.data?.globalpreferences?.languages;
    });
    this.currenciesService.getCurrencies()
    .pipe(takeUntil(this.destroy))
    .subscribe((currencies) => {
      this.allCurrencies = currencies?.response?.currencies;
    });
   
  }

  getPreference(prefer: IPreferenceData) {
    if (
      prefer?.label === 'Edit Global Preferences' ||
      prefer?.label === 'Bearbeiten Sie globale Einstellungen' ||
      prefer?.label === 'Editar preferencias globales' ||
      prefer?.label === 'Modifier les préférences globales' ||
      prefer?.label === 'Modifica le preferenze globali' ||
      prefer?.label === 'グローバル設定の編集' ||
      prefer?.label === '전역 기본 설정 편집' ||
      prefer?.label === 'Editar preferências globais' ||
      prefer?.label === '编辑全局首选项' ||
      prefer?.label === '*Edit Global Prefrences*'
    ) {
      this.preferencesForm.setValue({
        langaugesControl: this.preferenceMemory?.language
          ? this.preferenceMemory.language
          : 'English',
        currencyControl: this.currencyName
          ? this.currencyName
          : 'United States Dollar',
        unit: this.preferenceMemory?.uom
          ? this.preferenceMemory.uom
          : 'Imperial',
      });
      this.isSettingsClicked = true;
    }
    if (
      prefer?.label === 'Logout' ||
      prefer?.label === 'Ausloggen' ||
      prefer?.label === 'Se déconnecter' ||
      prefer?.label === 'Cerrar sesión' ||
      prefer?.label === 'Disconnettersi' ||
      prefer?.label === 'ログアウト' ||
      prefer?.label === '로그 아웃' ||
      prefer?.label === 'Sair' ||
      prefer?.label === '登出' ||
      prefer?.label === '*Logout*'
    ) {
      this.ssoService.logoutDI(true);
    }
  }

  closePrefernce() {
    this.isSettingsClicked = !this.isSettingsClicked;
  }


  getExactValue(val: any) {
    return val.split(':')[0].trim()
  }

  onSubmit() {
    let preferences = this.preferencesForm?.value;
    if (this.languages) {
      this.languageKey = this.languages.find(
        (lang: { language: string | null | undefined }) =>
          lang.language === this.preferencesForm.get('langaugesControl')?.value
      );
    }
    if(this.allCurrencies) {
      this.currCode = this.allCurrencies.find((curr: any) => curr.currencyName  === this.preferencesForm.get('currencyControl')?.value);
    }
    this.payload = {
      "userPreferences": {
        "emailId": this.email,
        "langKey": this.languageKey?.langkey,
        "language": this.getExactValue(preferences?.langaugesControl) ? this.getExactValue(preferences?.langaugesControl) : 'English',
        "currency": this.currCode?.currencyCode,
        "uom": preferences?.unit,
      },
    };
    this.preferencesForm?.patchValue(this.payload)
   
    if(this.payload) {
      this.persistPreferencesService.postPreferences(this.payload)
      .pipe(takeUntil(this.destroy))
      .subscribe();
    }
    this.preferencesForm.reset();
    this.isSettingsClicked = false;
    this.preferenceChanged = true;
    this.getCDMDataService.passValue(this.preferenceChanged);

  }

  ngOnDestroy() {
    this.destroy.next(void 0);
  }

}