import { Component,  OnDestroy,  OnInit } from '@angular/core';
import { EBR, MYHOMEPAGE, NO_APPS, NO_APPS_LOGIN_STATUS, TVD } from '../../../constants/DI.contants';
import { DISSOService } from '../../../../services/di-sso.service';
import { GetCDMDataService } from '../../../../services/get-cdmdata.service';
import { SpinnerService } from '../../../../services/spinner-service.service';
import { TranslationService } from '../../../../services/translation.service';
import { PersistPreferencesService } from '../../../../services/persist-preferences.service';
import { IApp, IUser } from '../../../../core/classes/apps.interface';
import { IUserPreference, IuserSelection } from '../../../../core/classes/preference.interface';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent implements OnInit, OnDestroy {
  MYHOMEPAGE = MYHOMEPAGE;
  homeCards: IApp[] = [];
  email: string = '';
  userPreferenceChanged: string = '';
  dropDown : any;
  EBR = EBR;
  TVD = TVD;
  status: string = '';
  userName: string | unknown = '';
  cardsLength: number | undefined = undefined;
  NO_APPS = NO_APPS;
  NO_APPS_LOGIN_STATUS = NO_APPS_LOGIN_STATUS;

  constructor(
    private ssoService: DISSOService,
    private cdmService: GetCDMDataService,
    private spinner: SpinnerService,
    private persistPreferencesService: PersistPreferencesService,
    public translate: TranslationService) {
    this.cdmService.SharingUserName.subscribe(name => {
      this.userName = name;
    })
  }

  ngOnInit(): void {
    this.cdmService.userPreferenceSubject.subscribe((userPreference: string) => {
      this.userPreferenceChanged = userPreference;
      this.getUserInfo();
    });
    this.getUserInfo();
    this.email = this.ssoService.getUserMailID();
  }

  getUserInfo(): void {
    let spinnerRef = this.spinner.start();
    this.cdmService?.getUserApplications().subscribe(
      (CDMData: IUser) => {
        this.translate.getPersistLanguge();
        this.cardsLength = CDMData?.response?.application.length;
        this.cdmService.SharingUserName.next(CDMData?.response?.firstName.concat(' ').concat(CDMData?.response?.lastName));
        if (CDMData && CDMData?.response?.application.length > 0) {
          this.homeCards = CDMData?.response?.application.filter(
            (card: IApp) => card?.appDeepLink !== null && card.appDeepLink !== ''
          );
          this.getPersistLanguge();
        }
        this.spinner.stop(spinnerRef);
      },
      (error: Error) => {
        if (error) {
          this.spinner.stop(spinnerRef);
        }
      }
    );
  }

  gotTo(cardType: IApp): void {
    if (cardType) {
      window.open(cardType?.appDeepLink, '_blank');
    }
  }

  getPersistData(): void {
    this.cdmService.userPreferenceSubject.subscribe((preference: string) => {
      this.userPreferenceChanged = preference;
      this.getUserInfo();
    });
  }

  getPersistLanguge(): void {
    this.persistPreferencesService
      .getPreferences(this.email)
      .subscribe((userPreference: IUserPreference) => {
        console.log(userPreference)
        if (userPreference?.data?.userPreferences?.language) {
          let selection: IuserSelection = {language:userPreference?.data?.userPreferences?.language,currency: userPreference?.data?.userPreferences?.currency,uom: userPreference?.data?.userPreferences?.uom};
          this.dropDown = this.persistPreferencesService.passUserPreference(selection['language']);
          this.persistPreferencesService.passUserPreference(selection);
        } else {
          this.dropDown = 'English';
          this.persistPreferencesService.passUserPreference(this.dropDown);
        }
      });
        
  }

  ngOnDestroy(): void {
    this.cdmService.userPreferenceSubject.unsubscribe();
  }
}