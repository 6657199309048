<div class="DI" *ngIf="loginDisplay">
    <div class="DI-header">
        <app-header [name]="userName"></app-header>
    </div>
    <div class="DI-nav">
        <div class="DI-nav-icons" *ngFor="let icons of navIcons">
            <div class="active">
                <img [src]="icons" (click)="navigateToApp()" class="side-nav-icons" />
            </div>
        </div>
    </div>
    <div class="DI-main">
        <router-outlet></router-outlet>
    </div>
    <div class="DI-footer">
        <app-footer></app-footer>
    </div>
</div>