import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ICurrencies } from '../core/classes/currencies.interface';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  constructor(private http: HttpClient) {}

  getCurrencies(): Observable<ICurrencies> {
    return this.http.get<ICurrencies>(`${environment.MULE_BASE_URL}/users/applications/currencies`);
  }
}
