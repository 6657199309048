<div class="login">
  <p class="login-header">{{'DI.MYHOMEPAGE'| translate}}</p>

  <div class="no-apps" *ngIf="cardsLength === 0">
    <div class="no-apps-align">
      <h1 class="no-apps-heading">{{NO_APPS}}</h1>
    </div>
    <div class="no-apps-sub-heading">
      <p class="no-apps-sub-heading-align">{{NO_APPS_LOGIN_STATUS}}</p>
    </div>
  </div>
  <ng-container *ngIf="cardsLength" >
    <div class="login">
      <div [ngClass]=" homeCards.length > 1 ? 'multipe-cards' : 'single-card'">
        <div *ngFor="let card of homeCards">
          <app-card *ngIf="card?.appDeepLink">
            <app-card-details [details]="card" (click)="gotTo(card)"></app-card-details>
          </app-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>