import {  Injectable } from '@angular/core';
import {  Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SpinnerViewComponent } from '../shared/components/spinner-view/spinner-view.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private router: Router, private dialog: MatDialog) {}
  start(): MatDialogRef<SpinnerViewComponent> {
    const dialogRef = this.dialog.open(SpinnerViewComponent, {
      disableClose: true,
    });
    return dialogRef;
  }

  stop(ref: MatDialogRef<SpinnerViewComponent>) {
    ref.close();
  }
}
