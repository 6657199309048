import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {},
  authorities: {
    signUpSignIn: {
      authority: environment.SIGN_UP_SIGN_IN_AUTHORITY,
    },
  },
  authorityDomain: environment.AUTHORITYDOMAIN,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    postLogoutRedirectUri: environment.ECOLAB_WEB,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message) => {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const DIprotectedResources = {
  MULEAPI: {
    endpoint: `${environment.MULE_BASE_URL}/**`,
    scopes: [environment.MULE_READ_SCOPE],
  }
}
  export const DOTNETprotectedResources = {
    DOTNET: {
      endpoint: `${environment.DOTNET_BASE_URL}/**`,
      scopes: [environment.DOTNET_READ_SCOPE,environment.DOTNET_WRITE_SCOPE],
    },
};

export const loginRequest = {
 scopes: [],
};

export const silentRequest = {
 scopes: [],
  loginHint: '',
};
