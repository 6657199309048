import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spinner-view',
  templateUrl: './spinner-view.component.html',
  styleUrl: './spinner-view.component.scss',
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class SpinnerViewComponent {
  value: number = 80;
  constructor(
    public dialogRef: MatDialogRef<SpinnerViewComponent>
  ) {}
}
