import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() name: any;
  displayPrefernce: boolean = false;
  preference: boolean = false;

  @HostListener('document:click', ['$event']) onDocumentClick(event:Event) {
    this.preference = false;
    this.displayPrefernce = false;
  }
  

  openPreferenceMenu($event:Event) {
    this.preference = !this.preference;
    this.displayPrefernce = !this.displayPrefernce;
    $event.stopPropagation()
  }
}
