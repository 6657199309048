import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PersistPreferencesService } from './persist-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class DISSOService {
  activeAccount: any;
  accounts: any;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private http: HttpClient,
    private userpreferenceService: PersistPreferencesService
  ) { }

  userSSO(): Observable<any> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    );
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    );
  }

  checkProgress(): Observable<any> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    );
  }

  getAllAccountsLength(): any {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getLoggedInUserName(): any {
    return this.authService.instance.getAllAccounts()[0].name;
  }

  getAllClaims(): Observable<any> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter(
        (status: InteractionStatus) =>
          status === InteractionStatus.None ||
          status === InteractionStatus.HandleRedirect
      )
    );
  }

  checkAndSetActiveAccount(): void {
    this.activeAccount = this.authService.instance.getActiveAccount();
    console.log(this.activeAccount)
    if (
      !this.activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      this.accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(this.accounts[0]);
    }
  }

  getClaimsWithIdTokenClaims(): any {
    return this.authService.instance.getActiveAccount()?.idTokenClaims;
  }

  getUserMailID(): string {
    return this.authService.instance.getAllAccounts()[0].localAccountId;
  }

  logoutDI(loggedOutStatus: any): void {

    this.userpreferenceService.updateUserLoginStatus(
      this.authService.instance.getAllAccounts()[0].localAccountId,
      loggedOutStatus).subscribe(() => {
        this.authService.logoutRedirect({
          postLogoutRedirectUri: environment.ECOLAB_WEB,
        });
        this.authService.logout();
      });
  }

  checkLoggedOutService(): Observable<any> {
    const url = `${environment.DOTNET_BASE_URL}/globalpreferences/user/logout`
    return this.http.get<any>(url, {
      params: {
        EmailId: this.authService.instance.getAllAccounts()[0].localAccountId
      }
    })
  }
}
