import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CardComponent } from './components/card/card.component';
import { CardDetailsComponent } from './components/card-details/card-details.component';
import { FooterComponent } from './components/footer/footer.component';
import { TestCompComponent } from './components/card-details/test-comp/test-comp.component';
import { TestHeaderComponent } from './components/header/test-header/test-header.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { CoreModule } from '../core/core.module';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';  
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslationService } from '../services/translation.service';
// import {CookieService} from 'ngx-cookie-service';
import { EndUserSessionComponent } from './components/end-user-session/end-user-session.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
} 
@NgModule({
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    CardComponent,
    CardDetailsComponent,
    FooterComponent,
    TestCompComponent,
    TestHeaderComponent,
    PreferencesComponent,
    EndUserSessionComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [TranslationService,
    // CookieService
  ],
  exports: [
    HeaderComponent,
    CardComponent,
    CardDetailsComponent,
    TestCompComponent,
    TestHeaderComponent,
    PageNotFoundComponent,
    FooterComponent,
    PreferencesComponent,
    TranslateModule,
  ],
})
export class SharedModule {}
