<div class="heading">
    <div class="heading-wrapper">
        <div class="ecolab-icon">
            <a href="https://www.ecolab.com" id="ecolab-href">
                <svg id="ecolab-logo" class="ecolab-logo-tagline-svg-1" width="104" height="30" viewBox="0 0 104 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M38.6896 0C36.7385 0 34.8311 0.580184 33.2088 1.66719C31.5864 2.75419 30.3219 4.29918 29.5752 6.10679C28.8285 7.91443 28.6332 9.90343 29.0139 11.8224C29.3945 13.7414 30.3341 15.5041 31.7138 16.8875C33.0935 18.271 34.8513 19.2132 36.765 19.5949C38.6787 19.9766 40.6623 19.7807 42.465 19.0319C44.2677 18.2832 45.8084 17.0153 46.8925 15.3884C47.9765 13.7616 48.555 11.849 48.555 9.8925C48.5548 7.26893 47.5154 4.75284 45.6653 2.89769C43.8152 1.04253 41.306 0.000217914 38.6896 0ZM45.2696 14.8544L39.3789 11.7981C39.2549 11.7334 39.1135 11.7102 38.9753 11.7318C38.8372 11.7534 38.7095 11.8186 38.611 11.9181L33.9274 16.6253C33.9079 16.6451 33.882 16.6572 33.8544 16.6594C33.8267 16.6616 33.7992 16.6538 33.7768 16.6374C33.7544 16.6209 33.7387 16.5969 33.7325 16.5698C33.7263 16.5427 33.73 16.5142 33.743 16.4896L36.79 10.5836C36.8547 10.4593 36.878 10.3176 36.8566 10.1791C36.8352 10.0405 36.7703 9.9125 36.6712 9.81357L31.9753 5.11553C31.956 5.09598 31.9442 5.07016 31.9421 5.04269C31.94 5.01523 31.9478 4.98791 31.964 4.96566C31.9801 4.9434 32.0037 4.92765 32.0304 4.92123C32.0571 4.91481 32.0853 4.91814 32.1097 4.93063L38.0005 7.986C38.1245 8.05071 38.2659 8.07393 38.404 8.05236C38.5422 8.03079 38.6698 7.9655 38.7684 7.866L43.4536 3.15971C43.473 3.14031 43.4988 3.12851 43.5261 3.12642C43.5536 3.12434 43.5808 3.1321 43.603 3.14832C43.6252 3.16454 43.6409 3.18816 43.6473 3.21495C43.6537 3.24174 43.6504 3.26993 43.6379 3.29448L40.5909 9.20386C40.5263 9.32814 40.5029 9.46986 40.5243 9.60843C40.5458 9.74693 40.6107 9.87493 40.7098 9.97386L45.4007 14.6694C45.4188 14.689 45.4296 14.7141 45.4313 14.7407C45.433 14.7673 45.4256 14.7936 45.4101 14.8154C45.3947 14.8371 45.3724 14.8528 45.3468 14.8599C45.3211 14.867 45.2939 14.865 45.2696 14.8544Z"
                        fill="white" />
                    <path d="M49.3262 0.557617H55.589V13.9319H62.1304L60.3708 19.34H49.3262V0.557617Z" fill="white" />
                    <path
                        d="M0 0.558594V19.341H14.172V13.9329H6.26523V12.4003H13.1246V7.50093H6.26523V5.96667H14.172V0.558594H0Z"
                        fill="white" />
                    <path
                        d="M25.6993 13.9316C23.1014 13.9316 21.1919 12.4771 21.1919 9.9477C21.1919 7.41828 23.0719 5.96374 25.6993 5.96374H28.5791V0.555664H24.2717C17.9712 0.555664 14.5801 4.55605 14.5801 9.94356C14.5801 15.3311 17.9999 19.3364 24.3004 19.3364H28.5791V13.9283H25.6993V13.9316Z"
                        fill="white" />
                    <path
                        d="M83.0629 0.546875H92.9343C96.627 0.546875 98.8345 2.66376 98.8345 5.44628C98.8345 7.80557 97.6499 9.09414 96.1142 9.59871V9.6455C97.87 10.1526 99.4315 11.6178 99.4315 13.9631C99.4315 17.2938 97.2047 19.3384 92.8923 19.3384H83.0586V0.546875H83.0629ZM89.1725 11.7681V15.0701H91.2582C92.5383 15.0701 93.0975 14.5293 93.0975 13.4183C93.0975 12.3689 92.5675 11.7681 91.2582 11.7681H89.1725ZM89.1647 4.72476V7.58536H91.0787C92.2597 7.58536 92.7121 7.04051 92.7121 6.15382C92.7121 5.20385 92.2419 4.72311 91.0787 4.72311H89.1647V4.72476Z"
                        fill="white" />
                    <path
                        d="M82.3199 19.3402H75.5072L74.8312 16.8288H68.9309L68.2753 19.3402H61.4609L67.3664 0.538086H76.4411L82.3199 19.3402ZM71.9306 5.73414H71.8487L70.1602 12.13H73.6174L71.9306 5.73414Z"
                        fill="white" />
                    <path
                        d="M101.435 4.3881C100.37 4.3881 99.5586 3.62631 99.5586 2.5046C99.5586 1.38287 100.37 0.621094 101.435 0.621094C102.501 0.621094 103.312 1.38287 103.312 2.5046C103.312 3.62631 102.501 4.3881 101.435 4.3881ZM101.435 0.861874C100.514 0.861874 99.8122 1.5349 99.8122 2.50542C99.8122 3.47593 100.514 4.14896 101.435 4.14896C102.356 4.14896 103.058 3.47593 103.058 2.50542C103.058 1.5349 102.357 0.861874 101.435 0.861874ZM101.788 2.62047L102.25 3.51291H101.929L101.482 2.64265H101.035V3.51291H100.759V1.43218H101.601C101.976 1.43218 102.266 1.62037 102.266 2.03043C102.272 2.17022 102.226 2.30724 102.138 2.41584C102.05 2.52445 101.926 2.59719 101.788 2.62047ZM101.587 1.67625H101.037V2.38215H101.555C101.852 2.38215 101.993 2.27203 101.993 2.0329C101.993 1.79376 101.846 1.67625 101.587 1.67625Z"
                        fill="white" />
                </svg>
            </a>
        </div>
        <div  (click)="openPreferenceMenu($event)">
            <div class="heading-user">
                <span class="heading-user-photo">
                    <svg class="photo" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" id="photo">
                        <path
                            d="M16 2.6665C8.63996 2.6665 2.66663 8.63984 2.66663 15.9998C2.66663 23.3598 8.63996 29.3332 16 29.3332C23.36 29.3332 29.3333 23.3598 29.3333 15.9998C29.3333 8.63984 23.36 2.6665 16 2.6665ZM9.79996 24.6665C11.5466 23.4132 13.68 22.6665 16 22.6665C18.32 22.6665 20.4533 23.4132 22.2 24.6665C20.4533 25.9198 18.32 26.6665 16 26.6665C13.68 26.6665 11.5466 25.9198 9.79996 24.6665ZM24.1866 22.8265C21.9333 21.0665 19.0933 19.9998 16 19.9998C12.9066 19.9998 10.0666 21.0665 7.81329 22.8265C6.26663 20.9732 5.33329 18.5998 5.33329 15.9998C5.33329 10.1065 10.1066 5.33317 16 5.33317C21.8933 5.33317 26.6666 10.1065 26.6666 15.9998C26.6666 18.5998 25.7333 20.9732 24.1866 22.8265Z"
                            fill="white" />
                        <path
                            d="M16 7.99984C13.4266 7.99984 11.3333 10.0932 11.3333 12.6665C11.3333 15.2398 13.4266 17.3332 16 17.3332C18.5733 17.3332 20.6666 15.2398 20.6666 12.6665C20.6666 10.0932 18.5733 7.99984 16 7.99984ZM16 14.6665C14.8933 14.6665 14 13.7732 14 12.6665C14 11.5598 14.8933 10.6665 16 10.6665C17.1066 10.6665 18 11.5598 18 12.6665C18 13.7732 17.1066 14.6665 16 14.6665Z"
                            fill="white" />
                    </svg>
                </span>
                <span class="user-name" id="first-last-name"> {{name}}</span>
                <span class="user-drop-down" id="drop-down">
                    <span [ngClass]="displayPrefernce === true ? 'bg-color' : ''">
                    <i [ngClass]="displayPrefernce === true ? 'arrow-up' : 'arrow-down'" [id]="displayPrefernce"></i>
                    </span>
                </span>
            </div>
        </div>
    </div>
    <!-- Note: work around sugggested by UX and PO.To be removed once we get directions for hamburger menu implemetation from UX -->
    <div class="nav-icon">
        <img src="../../../../assets/images/home.png" class="nav-icon-img" />
    </div>
    <div class="user-select">
        <app-preferences [userPreferenceClicked]="preference"></app-preferences>
    </div>