<div class="login">
  <p class="login-header">{{'DI.MYHOMEPAGE'| translate}}</p>

  <div class="no-apps no-apps-mini" *ngIf="cardsLength === 0">
    <div class="no-apps-align">
      <h1 class="no-apps-heading">{{NO_APPS}}</h1>
    </div>
    <div class="no-apps-sub-heading">
      <p class="no-apps-sub-heading-align">{{NO_APPS_LOGIN_STATUS}}</p>
    </div>
  </div>
  <ng-container *ngIf="cardsLength" >
    <div class="row m-bottom">
      <div *ngFor="let card of homeCards" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <app-card *ngIf="card?.appDeepLink">
          <app-card-details [details]="card" (click)="gotTo(card)"></app-card-details>
        </app-card>
      </div>
    </div>
  </ng-container>
</div>

