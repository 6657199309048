import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { END_SESSION_PATH } from '../../constants/DI.contants';
import { EndUserSessionComponent } from '../../../shared/components/end-user-session/end-user-session.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    children: [
      { path: '', component: LoginPageComponent },
      { path: '', redirectTo: '/', pathMatch: 'full' },
    ],
    canActivate: [MsalGuard],
  },
  {
    path: END_SESSION_PATH,
    component: EndUserSessionComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
